<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const viewport = await useViewport()

</script>

<template>

  <div class="relative bg-white" aria-label="top-navigation" >
    <header class="header checkout-header">
      <div class="bar header__bar">
        <div class="container">
          <ul class="list-info list-unstyled justify-content-center">
            <li>
              <i class="ico-time">
                <img src="assets/images/svg/time.svg" alt="" width="27" height="27">
              </i>
              <span>{{ $t('header_bar.usp2') }}</span>
            </li>
          </ul><!-- /.list-info -->
        </div><!-- /.container -->
      </div><!-- /.bar header__bar -->
      <div class="header-placeholder" ref="header__placeholder" />
      <div class="header__inner" ref="header__inner">
        <div class="container">
          <div class="header__inner-content">
            <div class="header__inner-wrapper">
              <NuxtLink class="logo header__logo" :to="formatLink(`/`)">
                <img src="assets/images/svg/logo.svg" alt="">
              </NuxtLink>
            </div><!-- /.header__inner-wrapper -->

            <div class="d-none d-xl-inline-block" v-html="$t('checkout.support.headline')">

            </div>

            <div id="mobile-header-collapse" class="header__content" ref="header__content">
              <div class="header__content-inner" ref="header_inner">

                <div class="dropdown-custom dropdown-custom--alt d-none d-xl-inline-block">
                  <div class="dropdown__btn js-dropdown">
								<span class="dropdown__btn-image">
									<img src="assets/images/temp/switzerland.png" alt="" >
								</span>
                    <span class="dropdown__btn-inner" style="margin-left:5px;">
									<small>{{ $t('headerShippingLabel') }}</small>
								</span>
                  </div>

                  <div class="dropdown__inner">
                    <ul>
                      <li class="is-active">
                        <a href="#">
											<span class="dropdown__inner-image">
												<img src="assets/images/temp/switzerland.png" alt="">
											</span>

                          <span>Schweiz</span>
                        </a>
                      </li>
                    </ul>
                  </div><!-- /.dropdown__inner -->
                </div><!-- /.dropdown-custom -->

                <nav class="nav-access header__nav-access">
                  <ul>
                    <li>
                      <AccountMenu />
                    </li>
                  </ul>
                </nav><!-- /.nav-access -->
              </div><!-- /.header__content-inner -->
            </div><!-- /.header__content -->
          </div><!-- /.header__inner-content -->
        </div><!-- /.container -->
      </div><!-- /.header__inner -->

    </header>
  </div>
</template>
